

/* what we need to happen is that this function should return true or false.
 * if true then on the payments table we will pop the modal asking if they want to be charged twice
 *
 * cases
 * 1. one or more payments where the first payment covers total tuition but remaining payments do not cover the full tuition
 *      sol: card style window pop up asking if payment 1 wants to cover the rest of the tuition
 * 
 * 2. same as one but the payments cover the full tuition
 *      sol: card style window does not pop up
 * 
 * 3. one payment that covers the deposit and more but not the full tuition
 *      sol: card style window pops up asking if payment 1 wants to cover the rest of the tuition
 *          edge case: do we promp? if yes how do we split up the payments? one for deposit and one for tuition?
 *              ex: deposit is 1000 and user puts a payment of 1250. assuming they hit yes do we just do two payments (1000, rest of tuition) or do we
 *              (1000, 250, rest of tuition of date)
 * 
 * 4. same as 3 but cover the full tuition
 *      sol: card style window does not pop up
 * 
 * 5. one or more payments that do not cover the deposit
 *      sol: card style window does not pop up
 * 
 * 6. 
 * 
 */

// -1 means false and the modal will not pop up. anything else means true and the modal will pop up
export function handleDeposit(payments, deposit, tuition) {
    /* if deposit is zero then never pop the popover window(modal) */
    if(tuition <= 0) {
        return -1;
    }

    let index = 0;
    let totalPayment = 0;
    if(payments.length < 1) {
        return -1;
    }
    for(let i = 0; i < payments.length ; i++) {
        totalPayment += Number(payments[i].amount);
        // if(payments[i].amount >= deposit) {
        //     return 0; //we will only ever charged the first credit card twice if they answer yes to the modal question
        // }
        // else {
        //     deposit = deposit - payments[i].amount
        // }
    }
    if(totalPayment >= (deposit + tuition)) {
        return -1;
    }
    if(totalPayment < tuition){
        return 0;
    }

    return -1;
}

export function handleSecondCharge(payments, deposit ,tuition) {

    if(deposit <= 0) {
        deposit = 0;
    }

    let firstPayment = {...payments[0] };
    let totalPayment = 0;
    for(let i = 0; i < payments.length; i++) {
        totalPayment += Number(payments[i].amount);
    }
    if(totalPayment > deposit) {
        firstPayment.amount = tuition + deposit - totalPayment;

    }
    else {
        firstPayment.amount = tuition;
    }
    firstPayment.flagTuition = 1;
    firstPayment.paymentType = 'tuition';
    payments.push(firstPayment);


}

export function handlePaymentTypes(payments, deposit, tuition) {
    let flagDepositPaid = false;
    let currDeposit = deposit

    if(deposit <= 0) {
        flagDepositPaid = true;
    }

    for(let i = 0; i < payments.length; i++) {
        if(flagDepositPaid === false && Number(payments[i].amount) < currDeposit){
            payments[i].paymentType = 'deposit';
            currDeposit -= Number(payments[i].amount);
        }
        else if(flagDepositPaid === false && Number(payments[i].amount) == currDeposit) {
            payments[i].paymentType = 'deposit';
            currDeposit -= Number(payments[i].amount);
            flagDepositPaid = true;
        }
        else if(flagDepositPaid === false && Number(payments[i].amount) > currDeposit) {
            payments[i].paymentType = 'deposit';
            currDeposit = 0;
            flagDepositPaid = true;
        }
        else {
            payments[i].paymentType = 'tuition';
        }
        if(payments[i].flagTuition){
            payments[i].paymentType = 'tuition';
        }
        else{
            payments[i].flagTuition = '';
        }
    }
}