import React from "react";

export function DragAndDropThumbnail({
  DropzoneAreaBackground,
  getRootProps,
  getInputProps,
  DropzoneAreaText,
}) {
  return (
    <section className="DropzoneAreaContainer">
      <div className="DropzoneArea" style={DropzoneAreaBackground} {...getRootProps()}>
        <input {...getInputProps()} />
        <p>{DropzoneAreaText}</p>
      </div>
    </section>
  );
}
