import React, { useRef, useEffect } from "react";
import { useState } from "react";
import { Redirect } from "react-router-dom";
import Cleave from 'cleave.js/react';
import { ImageUploadSubmitButton } from "../components/ImageUploadSubmitButton";

import "../App.scss";
import {urlValidate} from '../urls/urls.js'



let student = {};

const Login = ({getStudent, isLoggedIn, setIsLoggedIn, setMessageX, setFlagErrorMessageX, setShowMessageX}) => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [studentId, setStudentId] = useState("");
    const [dob, setDob] = useState(""); //date of birth date
    const [isValidated, setIsValidated] = useState("");
    const [isFormReady, setIsFormReady] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // object for displaying errors
    const [errors, setErrors] = useState({
        idStudentError: false,
        idStudentErrorDescription: '',
        nameFirstError: false,
        nameFirstErrorDescription: '',
        nameLastError: false,
        nameLastErrorDescription: '',
        dobError: false,
        dobErrorDescription: '',
    });

    // ref for date field
    const dobRef = useRef();

    useEffect(() => {
        checkIsFormReady();
    }, [firstName, dob, lastName, studentId])

    const firstNameChangeHandler = (ev) => {
        setFirstName(ev.target.value);
        if(errors.nameFirstError) {
            setErrors((currentErrors) => {
                return { ...currentErrors ,
                nameFirstError: false};

            })
        }

    };
    const lastNameChangeHandler = (ev) => {
        setLastName(ev.target.value);
        if(errors.nameLastError) {
            setErrors((currentErrors) => {
                return { ...currentErrors ,
                nameLastError: false};

            })
        }

    };
    const studentIdChangeHandler = (ev) => {
        setStudentId(ev.target.value);
        if(errors.idStudentError) {
            setErrors((currentErrors) => {
                return { ...currentErrors ,
                idStudentError: false};

            })
        }
    };
    const dobChangeHandler = (ev) => {
        setDob(ev.target.value);
        // console.log('in the dob handler');
        // console.log(ev.target.value);
        if(errors.dobError) {
            setErrors((currentErrors) => {
                return { ...currentErrors ,
                dobError: false};

            })
        }
    };

    function checkIsFormReady() {
        if(lastName && firstName && studentId && dob) {
            setIsFormReady(true);
        }
        else{

        }

    }

    function showMessagePopUp() {

    }

    // const cors = require('cors');

    const studentSubmitHandler = (ev) => {
        ev.preventDefault(); //vanilla js that prevents the browser from loading after submit
        if(!isFormReady) {
            return;
        }
        setIsLoading(true);
        let jsonData = {
            nameLast: lastName,
            nameFirst: firstName,
            idStudent: studentId,
            dob: dob,
        };
        jsonData = JSON.stringify(jsonData);
        // console.log(jsonData);
        //post request to server . fetch returns a promise
        fetch(urlValidate, {
            method: "POST",
            body: jsonData,
            headers: {
                "Content-Type": "application/json"
            },
        })
        .then((response) => response.json()) //.json returns a promise
        .then((body) => {
            student = { ...body };
            console.log(body);
            if (body.found === true) {
                const studentStorage = window.sessionStorage;
                studentStorage.setItem("found", body.found);
                studentStorage.setItem("idStudent", body.idStudent);
                studentStorage.setItem("depositDue", body.depositDue);
                studentStorage.setItem("totalDue", body.totalDue);
                studentStorage.setItem("idRecord", body.idRecord);
                studentStorage.setItem("squareCustomerID", body.squareCustomerID);
                studentStorage.setItem("isBridge", body.isBridge == 0 ? false : true );
                console.log(body);
                getStudent(body);
                setIsValidated(1);
                setIsLoggedIn(true);

                // console.log("in the response for body")
                // console.log(body);
                // window.location.href("/payment")
            } else if (body.error === true) {
                setIsValidated(0);
                //if the key does not exist then the error is false. if the key exists then set the error to true
                setErrors({
                    idStudentError: (body.idStudentError ? body.idStudentError : false),
                    idStudentErrorDescription: (body.idStudentError ? body.idStudentErrorDescription : ''),
                    nameFirstError: (body.nameFirstError ? body.nameFirstError : false),
                    nameFirstErrorDescription: (body.nameFirstError ? body.nameFirstErrorDescription : ''),
                    nameLastError: (body.nameLastError ? body.nameLastError : false),
                    nameLastErrorDescription: (body.nameLastError ? body.nameLastErrorDescription : ''),
                    dobError: (body.dobError ? body.dobError : false),
                    dobErrorDescription: (body.dobError ? body.dobErrorDescription : ''),
                });
                setIsFormReady(false);
            }
            setIsLoading(false);
        })
        .catch((error) => {
            setIsValidated(-1);
            setIsLoading(false);

        })
    };
    const CheckValidation = () => {
        if (isValidated === "") {
            return "";
        } else if (isValidated === 1) {
            // console.log(student);
            return <Redirect to="/dashboard" student={student} />;
        } else if (isValidated === 0) {
            return (
                <span className="errorText" style={{textAlign: "center"}}>
                    Student not found. Please double check you entered the student information correctly.
                </span>
            );
        } else {
            return (
                <span className="errorText" style={{textAlign: "center"}}>
                    Website is down. Please Try again later.
                </span>
            );
        }
    };

    return (
        <div className="Lookup">
            <div className="formContainer">
                <form onSubmit={studentSubmitHandler}>
                    <input
                        // add red border if there is an error
                        className={errors.idStudentError ? "errorBorder" : ""}
                        type="number"
                        name="idStudent"
                        placeholder="CSSSA ID"
                        onChange={studentIdChangeHandler}
                        value={studentId}
                        onInput={(e) => {
                            // prevent user from entering letters or numbers larger than 4 digits
                            const studentId = e.target.value;
                            const studentIdWithoutLetters = studentId.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
                            const fourDigitStudentId = studentIdWithoutLetters.substring(0, 4)
                            e.target.value = fourDigitStudentId
                        }}
                    />
                    {/* show error if it exists */}
                    {errors.idStudentError && (
                        <span className="errorText">
                            {/* Not a valid student ID, a student ID only has 4
                            numbers. */}
                            {errors.idStudentErrorDescription}
                        </span>
                    )}

                    <input
                        // add red border if there is an error
                        className={errors.nameFirstError ? "errorBorder" : ""}
                        type="text"
                        name="nameFirst"
                        placeholder="Student First Name"
                        value={firstName}
                        onChange={firstNameChangeHandler}
                    />
                    {/* show error if it exists */}
                    {errors.nameFirstError && (
                        <span className="errorText">
                            {/* Student's first name not found. */}
                            {errors.nameFirstErrorDescription}
                        </span>
                    )}

                    <input
                        // add red border if there is an error
                        className={errors.nameLastError ? "errorBorder" : ""}
                        type="text"
                        name="nameLast"
                        value={lastName}
                        placeholder="Student Last Name"
                        onChange={lastNameChangeHandler}
                    />
                    {/* show error if it exists */}
                    {errors.nameLastError && (
                        <span className="errorText">
                            {/* Student's last name not found. */}
                            {errors.nameLastErrorDescription}
                        </span>
                    )}

                    <Cleave
                        options={{
                            date: true,
                            datePattern: ["m", "d", "Y"],
                        }}
                        onChange={dobChangeHandler}
                        // add red border if there is an error
                        className={errors.dobError ? "errorBorder" : ""}
                        type="text"
                        ref={dobRef}
                        name="dob"
                        value={dob}
                        placeholder="Date of Birth - mm/dd/yyyy"
                    />
                    {/* show error if it exists */}
                    {errors.dobError && (
                        <span className="errorText">
                            {/* Please use the format mm/dd/yyyy */}
                            {errors.dobErrorDescription}
                        </span>
                    )}
                    <ImageUploadSubmitButton
                        isFormReady={isFormReady}
                        loading={isLoading}
                    />

                    {/* <input
                        className={
                            isFormReady
                                ? "submitButton"
                                : "submitButton submitButtonDisabled"
                        }
                        type="submit"
                        name="submit"
                    ></input> */}
                </form>
                <CheckValidation isValidated={isValidated} />
            </div>
        </div>
    );
};

export default Login;
