import React from "react";

const InputField = ({name, type, placeholder, defaultValueTest, onChangeHook, maxLength, errors}) => {

    function hasError() {
        if(!errors) {
            return false;
        }
        else if(errors.error) {
            return true;
        }
        else
        {
            return false;
        }
    }


    return (
        <>
            <input
                // add red border if there is an error
                className={hasError() ? "errorBorder" : ""}
                defaultValue={defaultValueTest}
                type={type}
                name={name}
                placeholder={placeholder}
                onChange={onChangeHook}
                maxLength={maxLength}
            />
            {/* show error if it exists */}
            {hasError() &&
                <span className="errorText">
                    {errors.errorDescription}
                </span>

            }
        </>
    )
}

export default InputField;