import './Modal.scss'
import React from 'react'


const Modal = (props) => {
    const classes = 'Modal ' + props.className

    return (
        <>
            <div className='ModalOverlay'></div>
            <div className='Modal'>
                {props.children}
            </div>
        </>
    )
}

export default Modal