import React from "react";
import { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button } from "@mui/material";

import ProductListItemSelector from "./ProductListItemSelector";

/* styles */
import './Products.scss'

import { urlGetFieldTrips } from "../urls/urls";

    const SHIRT_ID = "FT0004"


const Products = (props) => {

    const [pageIsLoading, setPageIsLoading] = useState(false);
    const [products, setProducts] = useState(props.products);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSelected, setIsSelected] = useState(props.isSelected);
    const [squareLineItems, setSquareLineItems] = useState(props.squareLineItems);
    const [goToCartFlag, setGoToCartFlag] = useState(false);

    let disabledButton = Object.keys(isSelected).length === 0;
    let numOfItems = Object.keys(isSelected).length;


    // test for empty student object
    const emptyStudentObject = Object.keys(props.student).length === 0;

    // test for empty session object
    const emptySessionObject = sessionStorage.length === 0;
            // console.log(`this is the the sessions object ${sessionStorage}`);
    useEffect(() => {

        // student object is NOT empty and session object is empty
        if (emptySessionObject) {
            // console.log(`error this is the the sessions object ${sessionStorage}`);
            return ( <Redirect to='/' />)

        }
        else {
            if('products' in sessionStorage){
                setProducts(JSON.parse(sessionStorage.products))
                props.setProducts(JSON.parse(sessionStorage.products));
                setPageIsLoading(false);
                setIsLoaded(true);
                console.log(JSON.parse(sessionStorage.products))
            }
            else {
                setPageIsLoading(true);

                let jsonData = {
                    idStudent: sessionStorage.idStudent
                };
                jsonData = JSON.stringify(jsonData);
                // console.log(`this is the useRef the data is: ${jsonData}`);
                //post request to server . fetch returns a promise
                fetch(urlGetFieldTrips, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => response.json()) //.json returns a promise
                .then((body) => {
                    if (!body.error) {
                        setProducts(body);
                        props.setProducts(body);
                        setPageIsLoading(false);
                        setIsLoaded(true);
                        sessionStorage.setItem('products', JSON.stringify(body));
                    }
                    else if(body.error) {
                        // console.log(`error this is the the sessions object ${sessionStorage}`);
                        return ( <Redirect to='/' />);
                    }
                });

            }
        }


    }, []);

    function handleSelection(id, product) {

        if([id] in isSelected) {
            //id exists to lets remove from is selection to give user feed back that this item was deselected
            let copyIsSelected = {...isSelected};
            delete copyIsSelected[id]
            setIsSelected(currSelected => ({
                ...copyIsSelected
            }));
            props.setIsSelected(currSelected => ({
                ...copyIsSelected
            }));

            let newSquareLineItems = [];

            Object.entries(copyIsSelected).forEach( entry => {
                let squareLineItemObject = {};
                const [key, value] = entry;
                squareLineItemObject['amount'] = value.fieldData.Unite_Price_NUMBER * 100;
                squareLineItemObject['label'] = value.fieldData.Name;
                squareLineItemObject['id'] = value.fieldData._ID_Readable;
                newSquareLineItems.push(squareLineItemObject)
            })

            console.log(newSquareLineItems);

        }
        else {
            /* item is not in our selected json object so we need to add it to change that style in the UI */
            let newSelectedItem = {[id]: product}
            if(product.fieldData._ID_Readable === "FT0004") {
                newSelectedItem[id].size = props.size;

            }
            setIsSelected(currSelected => ({
                ...currSelected,
                ...newSelectedItem

            }))
            props.setIsSelected(currSelected => ({
                ...currSelected,
                ...newSelectedItem

            }))
            console.log(`added into our global selection object, the id is: ${id}`);

            let newSquareLineItems = [];

            Object.entries(props.isSelected).forEach( entry => {
                let squareLineItemObject = {};
                const [key, value] = entry;
                squareLineItemObject['amount'] = value.fieldData.Unite_Price_NUMBER * 100;
                squareLineItemObject['label'] = value.fieldData.Name;
                squareLineItemObject['id'] = value.fieldData._ID_Readable;
                newSquareLineItems.push(squareLineItemObject)
            })

            let squareLineItemObject = {};
            squareLineItemObject['amount'] = product.fieldData.Unite_Price_NUMBER * 100;
            squareLineItemObject['label'] = product.fieldData.Name;
            squareLineItemObject['id'] = product.fieldData._ID_Readable;
            newSquareLineItems.push(squareLineItemObject)

            console.log(newSquareLineItems);


        }

        console.log(props.isSelected)
    }

    function checkoutButtonText() {
        if(numOfItems === 0 || numOfItems < 0) {
            return "Proceed to checkout"
        }
        else if(numOfItems > 1) {
            return "Proceed to checkout (" + numOfItems + " items)"
        }
        else {
            return "Proceed to checkout (" + numOfItems + " item)"

        }
    }

    function GoToCart() {
        return <Redirect to="/productCart" 
                student={props.student}
                products={products}
                isSelected={isSelected} />;
    }

    function handleGoToCart() {
        setGoToCartFlag(true);
        props.handleCartAddition(isSelected);
        handleTotals();
        console.log(`openModal is: ${props.openModal}`)
        if(props.openModal) {
            props.setOpenModal(false);
        }
        else {
            props.setOpenWarningModal(true);
        }
    }

    function handelSizeChange(newSize) {
        let currentSelected = {...isSelected}
        currentSelected[SHIRT_ID]['size'] = newSize
        setIsSelected((currSelected) => {
            return {
                ...currentSelected
            }
        })
        props.setSize(newSize);
    }

    function handleTotals() {
        props.updateTotals(isSelected)
    }

    function RenderList() {
        if(isLoaded) {
            return (
                <>
                    <h1 className="productsTitle"> Please select your items </h1>
                    {pageIsLoading && <h1> LOADING </h1>}
                        {isLoaded && <Grid container spacing={1}>

                            {products.map((product, index) => {
                                return (
                                    <ProductListItemSelector
                                        item={product}
                                        handleSelection={handleSelection}
                                        isSelected={isSelected}
                                        cart={props.cart}
                                        key={product.fieldData._ID_Readable}
                                        subSelector={product.fieldData._ID_Readable === "FT0004" && product.fieldData._ID_Readable in isSelected? true : false}
                                        subValue={props.size}
                                        subLabel={'Size'}
                                        setItem={handelSizeChange}
                                    />

                                )
                            })}


                        </Grid>}
                    <Button 
                        variant="contained" 
                        className="goToCartButton"
                        disabled={disabledButton && true}
                        onClick={handleGoToCart}>
                        {checkoutButtonText()}
                        
                    </Button> 

                </>
            )

        }
        else {
            return null;
        }
    }

    return(
        <div className="mainDiv">

            <RenderList />
            {goToCartFlag && <GoToCart isSelected={isSelected} products={products} />}

        </div>
    )


}


export default Products