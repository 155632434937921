import { CurrencyText } from './CurrencyText';
import React from "react";
import "./AmountDue.scss";

export function AmountDueSingle({totalDue}) {
    return (
      <div className="amountDue">
        <p>Order Total: <CurrencyText value={totalDue} /></p>
      </div>
    );
}
