import { RadioButton } from './../components/RadioButton';
import React , {useState} from "react";
import "./PaymentModal.scss";


export default function Modal({ open, onClose, modalValueHandler, ccNumbers }) {

    const headerText = "Would you like to charge the tuition balance on the card ending with " + ccNumbers + " to complete your pre-registration? " +
    " The tuition balance will NOT be charged until June 2.";
    const [radioButtonValue, setRadioButtonValue] = useState('');

    const handleRadioButtonClick = (ev, value) => {
        setRadioButtonValue(value);
        modalValueHandler(value);
    }

    if (!open) return null; // render nothing if open is false

    return (
        <>
            <div className="PaymentModalOverlay" /> {/* black background */}
            <div className="PaymentModal">
                <h3>{headerText}</h3>
                <RadioButton labelText={"\"YES\" of course"} value={'Yes'} setValue={handleRadioButtonClick}/>
                <RadioButton labelText={"\"NO\", another form of payment will be provided on or before June 2"} value={'No'} setValue={handleRadioButtonClick}/>
                <button className="submitButton" onClick={(ev) => onClose(ev, radioButtonValue)}>Confirm</button>
            </div>
        </>
    );
}
