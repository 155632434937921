
import React from "react";
import { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button } from "@mui/material";
import AddIcon from '@mui/icons-material/Add'
import PaymentSingle from "./PaymentSingle";
import ProductsListRow from "./ProductsListRow";
import Modal from "../components/UI/Modal";
import Products from "./Products";
import ModalMessage from "./ModalMessage";

    const SHIRT_ID = "FT0004"

const ProductCart = (props) => {

    const [size, setSize] = useState(props.size);

    let selectedProducts = []
    let total = 0;

    for(let key in props.isSelected) {
        total += props.isSelected[key].fieldData.Unit_Price_NUMBER 
    }

    const [selectedList, setSelectedList] = useState(props.isSelected); //object that stores all selected products
    const [squareLineItems, setSquareLineItems] = useState(props.squareLineItems);
    const [balance, setBalance] = useState(total); //variable to hold the total
    const [openModal, setOpenModal] = useState(false);
    const [openWarningModal, setOpenWarningModal] = useState(false);

    console.log(props.isSelected);

    function handleSelectionAction(id, product) {

        if([id] in props.cart) {
            //id exists to lets remove from is selection to give user feed back that this item was deselected
            let copyIsSelected = {...props.cart};
            delete copyIsSelected[id]
            props.setCart(currSelected => ({
                ...copyIsSelected
            }));
            setSelectedList(currSelected => ({
                ...copyIsSelected
            }))
            props.updateTotals(copyIsSelected)
            props.moveToSelectedArray(copyIsSelected);
        }
        else {
            /* item is not in our selected json object so we need to add it to change that style in the UI */
            let newSelectedItem = {[id]: product}
            if(product.fieldData._ID_Readable === "FT0004") {
                newSelectedItem[id].size = size;

            }
            props.setCart(currSelected => ({
                ...currSelected,
                ...newSelectedItem

            }))
            setSelectedList(currSelected => ({
                ...currSelected,
                ...newSelectedItem
            }))
            let currSelected = {
                ...newSelectedItem,
                ...props.cart
            }
            props.updateTotals(currSelected)
            console.log(`added into our global selection object, the id is: ${id}`);
        }

    }

    function handelSizeChange(newSize) {
        let currentSelected = {...props.cart}
        currentSelected[SHIRT_ID]['size'] = newSize
        props.setCart((currSelected) => {
            return {
                ...currentSelected
            }
        })
        setSelectedList((currSelected) => {
            return {
                ...currentSelected
            }
        })
        props.setSize(newSize);
        props.moveToSelectedArray(currentSelected)
    }

    function updateTotals(listObject) {
        let total = 0;
        for(let value of Object.values(listObject)) {
            console.log("TEST");
            console.log(value);
            total += value.fieldData.Unit_Price_NUMBER;
        }
        setBalance(total);
        props.setBalance(total);
    }

    function handleAddProducts() {
        setOpenModal(true);
    }

    return (
         <div className="mainDiv" >
            {/*{props.openWarningModal && <ModalMessage setOpenWarningModal={props.setOpenWarningModal} />}*/}
            <Grid container id="addproductbutton">
            <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddProducts}>
                Add Items
            </Button>
            </Grid>

            {openModal && <Modal>

                <Products
                    student={props.student}
                    products={props.products}
                    setProducts={props.setProducts}
                    isSelected={selectedList}
                    setIsSelected={setSelectedList}
                    squareLineItems={squareLineItems}
                    setSquareLineItems={setSquareLineItems}
                    setOpenModal={setOpenModal}
                    openModal={openModal}
                    handleCartAddition={props.handleCartAddition}
                    cart={props.cart}
                    size={props.size}
                    setSize={props.setSize}
                    balance={props.balance}
                    updateTotals={props.updateTotals}
                    setFlagErrorMessage={props.setFlagErrorMessage}
                    moveToSelectedArray={props.moveToSelectedArray}
                ></Products>

            </Modal>}

            <Grid 
                container
                direction={"row"}
                justifyContent={"flex-start"}
                alignItems={"center"}
            >

                {props.selectedArray.map((product, index) => {
                    return(
                        <ProductsListRow 
                            product={product}
                            subLabel={"size"}
                            subValue={props.size}
                            setItem={handelSizeChange}
                            key={product.fieldData._ID_Readable}
                            handleSelectionAction={handleSelectionAction}
                        />

                    )


                })}




            </Grid>
            <PaymentSingle 
                balance={props.balance}
                cart={props.cart}
                setProducts={props.setProducts}
                handleSelectionAction={handleSelectionAction}
                squareLineItems={squareLineItems}
                setSquareLineItems={setSquareLineItems}
                student={props.student}
                setShowMessage={props.setShowMessage}
                setMessage={props.setMessage}
                showMessage={props.showMessage}
                message={props.message}
                setFlagErrorMessage={props.setFlagErrorMessage}
            />

        </div>
    )

}

export default ProductCart