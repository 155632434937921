
import React from "react";
import { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import ProductListSubItemSelector from "./ProductListSubItemSelector";



const ProductsListRow = (props) => {

    console.log(props);

    function ShirtDropdown() {
        console.log('in dropdown');
        console.log(props.product);
        if(props.product.size) {
            return (
                <Grid item xs={3} >
                    <ProductListSubItemSelector
                        value={props.product.size}
                        label={props.subLabel}
                        setItem={props.setItem}
                    />
                </Grid>
            )
        }
        else {
            return (
                <Grid item xs={3} ></Grid>
            )
        }
    }

    function handleClick() {
        props.handleSelectionAction(props.product.fieldData._ID_Readable, props.product)
    }

    /*can have up to a total of xs=12 */
    return(
    <><Grid container direction={"row"} justifyContent={"flex-start"} alignItems={"center"} sx={{height: "65px"}} className="cartitem">
        <Grid item xs={1} alignItems={"center"} >
            <IconButton onClick={handleClick}>
                <DeleteIcon   />
            </IconButton>
        </Grid>
        
        <Grid item xs={2}>
            <p class="eventDate">{props.product.fieldData.Date_Event.substr(0,5)}</p>

        </Grid>

        <Grid item xs={5} className="eventName cartEventName">
            {props.product.fieldData.Name}
            <p class="itemSubDescription">{props.product.fieldData.WEB_description}</p>
        </Grid>

        

        <Grid item xs={1}>
            {"$" + props.product.fieldData.Unit_Price_NUMBER} 
        </Grid>

        <ShirtDropdown></ShirtDropdown>
        </Grid>

    </>

    )


}

export default ProductsListRow;
