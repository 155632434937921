import { CircularProgress } from "@mui/material";
import React from "react";
import "./../pages/Payment.scss";

export function ImageUploadSubmitButton({ isFormReady, loading }) {
    
    const className = isFormReady ? "submitButton" : "submitButton submitButtonDisabled"
    const style = { marginTop: "60px" }

    // only show submit text when not loading
    const submitText = loading ? "" : "Submit"
    
    return (
        <button type="submit" className={className} style={style}>
            {submitText}

            {/* loading icon */}
            {loading && (<CircularProgress size={24} sx={{ color: "white" }}/>)}
        </button>
    );
}
