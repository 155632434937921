import React from "react";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";

export function ImageLineItem({ currentImage, currentIndex, index, removeImage, setCurrentImageIndex, numberOfImages }) {

    const notFirstIndex = index !== 0
    
    const truncate = (str) => {
        const limit = 26
        return str.length > limit ? str.substring(0, limit) + "..." : str;
    }

    const fileName = truncate(currentImage.fileName)

    const textStyle = {
        cursor: numberOfImages > 2 ? "pointer" : ""
    }

    const containerStyle = {
        opacity: index === currentIndex ? "100%" : "40%", 
        transition: "all .25s ease-out", 
    }

    return (
            <React.Fragment key={index}>
                    {notFirstIndex && (
                        <div className="fileNameTextContainer" style={containerStyle}>
                            <p onClick={() => setCurrentImageIndex(index)} style={textStyle}>{fileName}</p>
                            <FontAwesomeIcon icon={faTrashCan} onClick={() => {removeImage(index)}} />
                        </div>
                    )}
            </React.Fragment>
    );
}
