import React from "react";
import CurrencyFormat from 'react-currency-format';

export function CurrencyText({ value }) {
    return (
        <CurrencyFormat
            value={value}
            displayType={"text"}
            thousandSeparator={true}
            prefix={"$"}
            style={{color: value < 0 ? "red" : ""}}
        />
    );
}
