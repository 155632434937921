





export async function getActiveProducts(urlGetFieldTrips) {
    let jsonData = {
        idStudent: sessionStorage.idStudent
    };
    jsonData = JSON.stringify(jsonData);
    // console.log(`this is the useRef the data is: ${jsonData}`);
    //post request to server . fetch returns a promise
    fetch(urlGetFieldTrips, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        },
    })
    .then((response) => response.json()) //.json returns a promise
    .then((body) => {
        console.log('THIS IS A TEST');
        console.log(body);

        if (!body.error) {
            sessionStorage.setItem('products', JSON.stringify(body));
            return body;
        }
        else if(body.error) {
            // console.log(`error this is the the sessions object ${sessionStorage}`);
            return body
        }
    })
    .catch()

}