import { CurrencyText } from './CurrencyText';
import React from "react";
import "./AmountDue.scss";

export function AmountDue({depositDue, totalDue}) {
    return (
      <div className="amountDue">
        <p>Deposit: <CurrencyText value={depositDue} /> Due by May 12</p>

        <p>Remaining Balance: <CurrencyText value={totalDue} /> Due by June 2</p>
      </div>
    );
}
