import "./App.scss";
import { useState, useEffect} from "react";
import {toast, ToastContainer} from 'react-toastify';
import Login from "./pages/Login";
import Payment from "./pages/Payment";
import Header from "./components/Header";
import Portal from "./pages/Portal";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { HashRouter } from "react-router-dom";
import ImageUpload from "./pages/ImageUpload";
import Products from "./pages/Products";
import ProductCart from "./pages/ProductCart";
import ProductsCartParent from "./pages/ProductsCartParent";
import Thankyou from "./pages/Thankyou";

function App() {
    const [student, setStudent] = useState({}); //student object with keys that store the id, amount due, deposit due,

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [pathRequested, setPathRequested] = useState();
    const saveStudent = (lookUpStudent) => {
        setStudent(lookUpStudent);
        console.log(lookUpStudent);
    };
    /*state variables to show message on the dashboard */
    const [showMessage, setShowMessage] = useState(false);
    const [message, setMessage] = useState('');
    const [flagErrorMessage, setFlagErrorMessage] = useState(false);

    // test for empty student object
    const emptyStudentObject = Object.keys(student).length === 0;

    // test for empty session object
    const emptySessionObject = sessionStorage.length === 0;
    useEffect(() => {

        // student object is empty and session object is NOT empty
        if (emptyStudentObject && !emptySessionObject) {
            
            // add data from session object to student object
            // remember to convert data types
            setStudent( 
            {
                found: Boolean(sessionStorage.found),
                idStudent: sessionStorage.idStudent,
                depositDue: parseInt(sessionStorage.depositDue),
                totalDue: parseInt(sessionStorage.totalDue),
                idRecord: parseInt(sessionStorage.idRecord),
                isBridge: Boolean(sessionStorage.isBridge)
            });
            
            console.log(`in the app.js and just set student up ${sessionStorage.idStudent}`);
            setIsLoggedIn(Boolean(sessionStorage.found))
            // console.log(`this is probs when loading from storage ${props.student.idStudent}`)
            // console.log(`this is local storage when loading from storage ${studentStorage.idStudent}`)
            //payments array will load with an empty idStudent so need to set it again
        }

        // student object is NOT empty and session object is empty
        if (emptySessionObject) {
            console.log(`this is the the sessions object ${sessionStorage}`);
            return ( <Redirect to='/' />)

            // add data from student object to session object
            // studentStorage.setItem("found", props.student.found);
            // studentStorage.setItem("idStudent", props.student.idStudent);
            // studentStorage.setItem("depositDue", props.student.depositDue);
            // studentStorage.setItem("totalDue", props.student.totalDue);
        }

        // console.log("Result:");
        // console.log("props.student: ", props.student);
        // console.log("studentStorage: ", studentStorage);
        // console.log("");
    }, []);

    useEffect(() => {
        // console.log(`useEffect in dashboard showMEssage is: ${showMessage}`)
        if(showMessage) {
            if(!flagErrorMessage) {
                toast.success(message, {
                    position: "top-center",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
            else {
                toast.error(message, {
                    position: "top-center",
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                })
            }
            setShowMessage(false);
            setFlagErrorMessage(false);
        }
    
    //   return () => {
    //     console.log(`useEffect cleanup in dashboard showMEssage is: ${showMessage}`)
    //       setShowMessage(false);
    //       setMessage('');
    //   }
    }, [showMessage, message, setShowMessage, setMessage, flagErrorMessage])



    return (
        <HashRouter>
            <Header />
            <ToastContainer/>
            {!isLoggedIn ? ( <Login getStudent={saveStudent} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn}></Login> ) :
                (
                    <Switch>
                        <Route path="/thankyou">
                            <div class="thank-you"><h1> Thank you, your receipt has been sent </h1></div>
                        </Route>
                        <Route path="/payments">
                            <Payment 
                                student={student} 
                                isLoggedIn={isLoggedIn} 
                                setStudent={setStudent}
                                setShowMessage={setShowMessage}
                                setMessage={setMessage}
                                showMessage={showMessage}
                                message={message}

                            />
                        </Route>
                        <Route path="/dashboard" student={student}>
                            <Portal
                                showMessage={showMessage}
                                setShowMessage={setShowMessage}
                                setMessage={setMessage}
                                message={message}
                                setIsLoggedIn={setIsLoggedIn}
                                student={student}
                            />
                        </Route>
                        <Route path="/imageUpload">
                            <ImageUpload 
                                student={student}
                                message={message}
                                showMessage={showMessage}
                                setShowMessage={setShowMessage}
                                setMessage={setMessage}
                            />
                        </Route>
                        <Route>
                            <ProductsCartParent
                                student={student}
                                setShowMessage={setShowMessage}
                                setMessage={setMessage}
                                showMessage={showMessage}
                                setFlagErrorMessage={setFlagErrorMessage}
                                message={message}
                            />
                        </Route>
                        {/* <Route path='/thankyou'>
                            <Thankyou/>
                        </Route> */}
                        <Route path="/">
                            <Login 
                                getStudent={saveStudent} 
                                isLoggedIn={isLoggedIn} 
                                setIsLoggedIn={setIsLoggedIn}

                                />
                            {/* <Payment student={student} /> */}

                        </Route>
                    </Switch>
                )
            }
        </HashRouter>
    );
}

export default App;
