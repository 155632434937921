import { RadioButton } from '../components/RadioButton';
import React , {useState} from "react";
import "./PaymentModal.scss";
import { Redirect } from 'react-router-dom';


export default function ModalDone({ open, onClose, modalValueHandler, ccNumbers, amountPaid }) {

    const headerText = "Thank you " + (amountPaid > 0 ? 'for a payment of $' + amountPaid : 'for our payment');
    const [flagRedirect, setFlagRedirect] = useState(false);

    const handleRadioButtonClick = () => {
        setFlagRedirect(true);
    }

    if (!open) return null; // render nothing if open is false

    return (
        <>
            <div className="PaymentModalOverlay" /> {/* black background */}
            <div className="PaymentModal">
                <h3>{headerText}</h3>
                <button className="submitButton" type="button" onClick={handleRadioButtonClick}>OK</button>
                {flagRedirect && <Redirect to="/dashboard"></Redirect>}
            </div>
        </>
    );
}
