import React from "react";
import "./Header.scss"

// images
import csssaLogo from "../images/CSSSA-logo.png";

const Header = () => {
    return (
        <div className="Header">
            <a href="https://www.csssa.ca.gov/" target="_blank" rel="noreferrer">
                <img src={csssaLogo} alt="CSSSA Logo" />
            </a>
            
        </div>
    );
};

export default Header;
