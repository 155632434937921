import React from "react";
import { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ProductListSubItemSelector from "./ProductListSubItemSelector";



const ProductListItemSelector = (props) => {

    function handleSelection(id, product) {
        props.handleSelection(id, product);
    }


    

    return (
        <Grid container xs={12}>
                <Button className={props.item.fieldData.Tickets_Remaining !== 0 ? "productListing" : props.item.fieldData.Type == 'Sales' ? "productListing" : "productListing sold-out"}
                    onClick={() => {handleSelection(props.item.fieldData._ID_Readable, props.item)}}
                    variant={props.item.fieldData._ID_Readable in props.isSelected ? "contained" : "text"}
                    disabled={props.item.fieldData._ID_Readable in props.cart ? true : false}
                >
                    <Grid item xs={3}>
                        <p class="eventDate">{props.item.fieldData.Date_Event.substr(0,5)}</p>

                    </Grid>
                    <Grid item xs={6} className="eventName">
                        {props.item.fieldData.Name}
                        <p class="itemSubDescription">{props.item.fieldData.WEB_description}</p>
                    </Grid>
                    <Grid item xs={3}>
                        {"$" + props.item.fieldData.Unit_Price_NUMBER}
                    </Grid>
                    
                </Button>
                {props.subSelector && <Grid item xs={3}>
                    <ProductListSubItemSelector
                        value={props.subValue}
                        label={props.subLabel}
                        setItem={props.setItem}
                         />
                </Grid>}

        </Grid>
    )

}

export default ProductListItemSelector