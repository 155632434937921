import { ImageUploadSubmitButton } from './../components/ImageUploadSubmitButton';
import { DragAndDrop } from "./DragAndDrop";
import React from "react";
import { useState, useEffect } from "react";
import { Redirect } from "react-router";
import { ToastContainer, toast } from "react-toastify";


import "./ImageUpload.scss";
import "./../App.scss";
import {urlFileUpload} from '../urls/urls'


// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

// Components
import { Link } from "react-router-dom";



// urlFileUpload = "http://localhost:63342/csssa/fileUpload.php";

const ImageUpload = ({student, setMessage, setShowMessage}) => {

    /* state variables to hold the files the user is uploading */
    const [file1, setFile1] = useState([]);
    const [file2, setFile2] = useState([]);
    const [file3, setFile3] = useState([]);
    /* prevent the user to reupload after uploading already */
    const [isSubmittedfile1, setIsSubmittedFile1] = useState(false);
    /*when uploading the documents this gets set to true (we are uploading) which then has a loading spinner appear*/
    const [loading , setLoading] = useState(false);
    /*  */
    const [alreadyUploaded , setAlreadyUploaded] = useState(false);
    const [uploadedRecords , setUploadedRecords] = useState([]);

    /*successful upload this redirect to dashboard */
    const [uploadConfirmed, setUploadConfirmed] = useState(false);

    /*boolean state variable if the form is ready to be submitted */
    const[isFormReady, setIsFormReady] = useState(false);

    useEffect(() => {
        if(!file1 || !file2 || !file1.length || !file2.length ) {
            setIsFormReady(false);
        }
        else {
            setIsFormReady(true);
        }
    }, [file1, file2, file3])

    useEffect(() => {


        if(!student.idStudent){
            return;
        }

        fetch(urlFileUpload + '/?id=' + student.idStudent, {
            method: "GET",
        })
        .then((response) => response.json())
        .then((body) => {
            console.log(body);
            let totalCount = 0;
            if(body.length > 3){
                for(let i = 0; i < body.length; i++) {

                    totalCount += body[i].fieldData.isUploaded_WEB;
                }
                if(totalCount > 2){
                    setUploadedRecords(body);
                    setAlreadyUploaded(true);

                }
            }

        });

        /*clean up function */
        return () => {
            setAlreadyUploaded(false);
        }
    
    }, [])

    
    function getFileArraySize(fileArr) {

        let size = 0;

        for(let i = 0; i < fileArr.length ; i++){
            size += fileArr[i].size;
        }
        return size;
    }
    

    function handleSubmit(ev) {
        ev.preventDefault();

        // console.log(`file stuff here is the path ${file1.path} and \n here is the whole object ${file1}`);
        if((file1.length < 1) || (file2.length < 1)) {
            console.log("one of the files is empty");
            return;
        }
        if(isFormReady === false){
            return;
        }
        let fileSize1 = getFileArraySize(file1);
        let fileSize2 = getFileArraySize(file2);

        /* dont let the images be bigger than 256 MB */
        if((fileSize1 + fileSize2 ) > 255000000) {
            setIsFormReady(false);
            toast.error('Your files are too big! please use smaller files. Our Limit is 256 MB ', {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
        
        if(loading){
            return;
        }
        setLoading(true);
        let file1Form = [...file1];
        let file2Form = [...file2];
        for(let i = 0 ; i < file1Form.length ; i++ ) {
            file1Form[i].docType = 'medical';
        }
        for(let i = 0 ; i < file2Form.length ; i++ ) {
            file2Form[i].docType = 'general';
        }

        let finalFileArray = [...file1Form, ...file2Form];

        let data1 = new FormData();
        let studentJson = JSON.stringify(student);
        console.log(finalFileArray)

        // data1.append('upfile', finalFileArray);
        data1.append('student' , studentJson)
        for(let i = 0; i < finalFileArray.length; i++){
            data1.append('upfile[]', finalFileArray[i]);
            data1.append('docType[]', finalFileArray[i].docType);
        }

        console.log(data1);
        if(isSubmittedfile1 === false){
            fetch(urlFileUpload, {
                method: 'POST',
                body: data1
            })
            .then((res) => {
                console.log(res);
                if(!res.ok){
                    return res.text().then(text => {throw new Error(text)})
                }
                return res.json()})
            .then((body) => {
                if(body.success) {
                    setUploadConfirmed(true);
                    setIsSubmittedFile1(true);
                    setLoading(false);
                    //when true will redirect to dashboard
                    //this promps the toast to pop up in the dashboard
                    setMessage('Thank you for uploading your medical documents');
                    setShowMessage(true);
                    //removed the spinning loader
                    // toast.success('Thank you for uploading your medical documents', {
                    //     position: "top-center",
                    //     autoClose: 5000,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     pauseOnHover: true,
                    //     draggable: true,
                    //     progress: undefined,
                    // });
                }
                else {
                    //removed the spinning loader
                    setLoading(false);
                    console.log(body)

                }
            })
            .catch( (error) => {
                //removed the spinning loader
                setLoading(false);
                console.log(error)
                console.log("Error when fetching");
            });
        }

    }


    return (
        <>
        {/* <ToastContainer /> */}
        <form onSubmit={handleSubmit}>
            <div className="ImageUploadContainer">
                <div className="ImageUpload">
                    <p className="ImageUpload__header">
                        {/*If your COVID vaccinations are included with your general immunizations, please upload the document again in this section. <br />*/}
                        {/*Click on image to upload second image.*/}
                    </p>
                    
                    <div className="DragAndDropContainer">
                        <DragAndDrop label={"Medical Card"} setFiles={setFile1} files={file1} loading={loading} numberOfImagesAllowed={2} />
                        <DragAndDrop label={"Immunization Record"} setFiles={setFile2} files={file2} loading={loading} numberOfImagesAllowed={2} />
                        {/*<DragAndDrop label={"Covid Vaccination"} setFiles={setFile3} files={file3} loading={loading} numberOfImagesAllowed={1} />*/}
                    </div>

                    {/*<label>Only JPEG and PNG images allowed. Please no HEIC, HEIF, PDF, TIFF or other formatted files. No files over 25 MB.</label>*/}

                    {alreadyUploaded && <p className="upload_message">
                        {'Your last successful upload was ' + uploadedRecords[0].fieldData.Date_Created_Timestamp}
                    </p>}
                    
                    <ImageUploadSubmitButton isFormReady={isFormReady} loading={loading} />
                </div>
            </div>
            <Link to="/dashboard">
                <FontAwesomeIcon icon={faHouse} className="homeButton"/>
            </Link>
        </form>
        {uploadConfirmed && <Redirect to="/dashboard"></Redirect>}
        </>
    );
};

export default ImageUpload;
