import React from "react";
import { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { Button } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


const ProductListSubItemSelector = (props) => {

  const handleChange = (event) => {
    props.setItem(event.target.value);
  };

  return (
    <div item>
      <FormControl required size="small" className="sizeSelector">
        <InputLabel id="demo-simple-select-required-label">{props.label}</InputLabel>
        <Select
          labelId="demo-simple-select-required-label"
          id="demo-simple-select-required"
          value={props.value}
          label={props.label}
          onChange={handleChange}
        >
          <MenuItem value={'S'}>S</MenuItem>
          <MenuItem value={'M'}>M</MenuItem>
          <MenuItem value={'L'}>L</MenuItem>
          <MenuItem value={'XL'}>XL</MenuItem>
          <MenuItem value={'XXL'}>XXL</MenuItem>
        </Select>
        <FormHelperText></FormHelperText>
      </FormControl>
    </div>
  );


}

export default ProductListSubItemSelector