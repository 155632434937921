import React, { useState, useEffect } from "react";
import CurrencyFormat from 'react-currency-format';
import CurrencyInput from "react-currency-input-field";
import Cleave from 'cleave.js/react';
import { Redirect, Link } from "react-router-dom";

import PaymentModal from './PaymentModal';
import { CheckFooter } from '../components/CheckFooter';
import { AmountDue } from "../components/AmountDue";
import InputField from "../components/InputFields";
import {handleDeposit, handlePaymentTypes, handleSecondCharge} from "./paymentHandleDeposit";
import ModalDone  from './ModalDone';

// CSS
import "../App.scss";
import "./Payment.scss";

// images
import visa from "../images/visa.png";
import mastercard from "../images/mastercard.png";

// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse } from "@fortawesome/free-solid-svg-icons";

import { urlValidationCC, urlGetStudent } from "../urls/urls";

// urls
// const urlValidationCC = "http://localhost:63342/csssa/ccValidate.php";
// const urlGetStudent = "http://localhost:63342/csssa/getPaymentInfo.php";
// const urlValidationCC = "https://payments.csssa.org/ccValidate.php"
// const urlGetStudent = "https://payments.csssa.org/getPaymentInfo.php"
// const urlValidationCC = "https://preregistration.csssa.org/ccValidate.php"
// const urlGetStudent = "https://preregistration.csssa.org/getPaymentInfo.php"


const Payment = (props) => {
    // const [paymentType, setPaymentType] = useState(); // variable for payment type
    const [currentPayment, setCurrentPayment] = useState(0); // variable for currently selected payment, by default it’s the first payment
    const [paymentConfirmed, setPaymentConfirmed] = useState(false) //variable for successful payment or not
    const [counter, setCounter] = useState(0) //variable for successful payment or not

    /* ERROR OBJECTS DEPENDING ON WHAT WENT WRONG */
    const [cvvError, setCvvError] = useState({}); //variable for cvv errors 
    const [cardHolderNameError, setCardHolderNameError] = useState({}); //variable for cvv errors 
    const [cardNumberError, setCardNumberError] = useState({}); //variable for cvv errors 
    const [expirationError, setExpirationError] = useState({}); //variable for cvv errors 
    const [amountError, setAmountError] = useState({}); //variable for cvv errors 
    /* END OF ERROR OBJECT STATE VARIABLES */

    const [isFormReady, setIsFormReady] = useState(false);
    const [modalValue, setModalValue] = useState(false);

    // State variable for amount due
    const [amountDue, setAmountDue] = useState();

    /* STATE VARIABLES FOR DEPOSIT AND TUITION */
    const [deposit , setDeposit] = useState(props.student.depositDue)
    const [balance, setBalance] = useState(props.student.totalDue - props.student.depositDue)

    /* STATE VARIABLES FOR OPENING AND CLOSING MODAL */
    const [isOpen, setIsOpen] = useState(false)

    /* STATE VARIABLES FOR NEW Modal that shows up when someone has finished paying*/
    const [isOpenModalDone , setIsOpenModalDone] = useState(false)
    const [amountPaid, setAmountPaid] = useState(0);


    // Session storage for student
    // const studentStorage = window.sessionStorage;

    // test for empty student object
    const emptyStudentObject = Object.keys(props.student).length === 0;

    // test for empty session object
    const emptySessionObject = sessionStorage.length === 0;
            // console.log(`this is the the sessions object ${sessionStorage}`);

    useEffect(() => {

        // student object is empty and session object is NOT empty
        if (emptyStudentObject && !emptySessionObject) {
            
            props.student.found = Boolean(sessionStorage.found)
            props.student.idStudent = sessionStorage.idStudent
            props.student.depositDue = parseInt(sessionStorage.depositDue)
            props.student.totalDue = parseInt(sessionStorage.totalDue)
            props.student.idRecord = parseInt(sessionStorage.idRecord)
            paymentsArray[0].idStudent = props.student.idStudent;

        }

        // student object is NOT empty and session object is empty
        if (emptySessionObject) {
            // console.log(`error this is the the sessions object ${sessionStorage}`);
            return ( <Redirect to='/' />)

        }
        let jsonData = {
            idStudent: sessionStorage.idStudent
        };
        jsonData = JSON.stringify(jsonData);
        // console.log(`this is the useRef the data is: ${jsonData}`);
        //post request to server . fetch returns a promise
        fetch(urlGetStudent, {
            method: "POST",
            body: jsonData,
            headers: {
                "Content-Type": "application/json",
            },
        })
        .then((response) => response.json()) //.json returns a promise
        .then((body) => {
            if (body.found === true) {
                /* update the session storage */
                sessionStorage.setItem("found", body.found);
                sessionStorage.setItem("idStudent", body.idStudent);
                sessionStorage.setItem("depositDue", body.depositDue);
                sessionStorage.setItem("totalDue", body.totalDue);
                sessionStorage.setItem("idRecord", body.idRecord);

                /* TO DO
                 * instead of setting the props.student.deposit use the setStudent and make an object and set that
                 *
                 */

                /*update the student */
                props.student.depositDue = body.depositDue
                props.student.totalDue = body.totalDue

                setDeposit(body.depositDue)
                setBalance(body.totalDue - body.depositDue)
                setAmountPaid(Number(body.depositPaid) + Number(body.tuitionPaid));

                /* if show message is true then reset it */
                if(props.showMessage){
                    props.setShowMessage(false);
                    props.setMessage('');
                }

                /*if there is not total due then pop up a modal letting the user know */
                if(Number(body.totalDue) <= 0) {
                    setIsOpenModalDone(true);
                }
                setPaymentConfirmed(false);
                props.setStudent(body);
                // console.log(body);
            }
            else if(body.error) {
                // console.log(`error this is the the sessions object ${sessionStorage}`);
                return ( <Redirect to='/' />);
            }
        });

    }, []);

    // every payment starts off with this object
    const defaultPaymentObject = {
        amount: "",
        creditCardNumber: "",
        expiration: "",
        CVV: "",
        cardHolderName: "",
        ccType: "",
        idStudent: props.student.idStudent,
        paymentType: 'tuition',
        key: counter ,
    };


    const [paymentsArray, setPaymentsArray] = useState([defaultPaymentObject]); // array of payment objects for the data of each payment form
    const numberOfPayments = paymentsArray.length;
    const firstPayment = currentPayment !== 0;
    const lastPayment = currentPayment !== numberOfPayments - 1;

    function clearErrors() {
        if(expirationError.error){
            setExpirationError({error: false});
        }
        if(cardHolderNameError.error){
            setCardHolderNameError({error: false});
        }
        if(cvvError.error){
            setCvvError({error: false});
        }
        if(cardNumberError.error) {
            setCardNumberError({error: false});
        }
        if(amountError.error){
            setAmountError({error: false});
        }
    }

    const handleAmountChange = (value, index) => {

        /* Theres something going on here where this handlers is running
         * too many times. theres times where its running with undefined values
         * im thinking that the component add the currency sign which causes the onchange 
         * event to trigger which cause this function to run. easy and dirty fix is to pass
         * a flag that is set only when we change the value and when the component
         * auto adds the currency sign it wont have a value so we can ignore and not run
         * the rest of the code 
         */

        // console.log(`the value for amount event change is: ${value} and the index is: ${index}`)
        // sum of every payment amount added together
        let totalAmount = 0;
        let depositAmount = props.student.depositDue;
        let balanceAmount = props.student.totalDue - depositAmount;

        // loop over payments array
        paymentsArray.forEach((payment) => {
            // convert from string to int
            let currentAmount = parseInt(payment.amount.replace(/[$,]/g, ''));

            // if current amount is a number
            if (!isNaN(currentAmount)) {
                console.log("current amount: ", currentAmount);
                // add each amount to total amount
                totalAmount += currentAmount;
            }
        });
        // console.log(`index is: ${index}`);
        // console.log(paymentsArray);
        if(index !== undefined){
            totalAmount -= paymentsArray[index].amount;
            totalAmount += value;
            // console.log(`total amount after adjustments is: ${totalAmount}`)
        }

        let remainderOfDeposit = totalAmount - depositAmount
        // console.log(remainderOfDeposit)
        if(remainderOfDeposit > 0){
            setDeposit(0);
            setBalance(balanceAmount - remainderOfDeposit)
        }
        else if(remainderOfDeposit <= 0) {
            setDeposit(depositAmount - totalAmount);
            setBalance(balanceAmount);
        }
        else {
            setDeposit(depositAmount - totalAmount);
            setBalance(balanceAmount);
        }

        if(totalAmount > (depositAmount + balanceAmount)) {
            setIsFormReady(false);
        }


    };

    // handles a change to the form's input fields
    const handleFieldChange = (e, index, key) => {
        // if the amount field is being changed
        if (key === "amount") {
            handleAmountChange(e.target.value.replace(/[$,]/g, ""), index);

            // remove $ and commas from amount before it goes into payments array
            paymentsArray[index][key] = e.target.value.replace(/[$,]/g, "");
            if(amountError.error){
                setAmountError({error: false});
            }
        } 
        
        // if the cc number field is being changed
        else if (key === "creditCardNumber") {
            // remove spaces from cc number before it goes into payments array
            paymentsArray[index][key] = e.target.value.replace(/\s/g, "");
            if(cardNumberError.error) {
                setCardNumberError({error: false});
            }
        } 
        
        // otherwise set value in payments array normally
        else {
            paymentsArray[index][key] = e.target.value; // change value in the paymentsArray

            if(key === "expiration" && expirationError.error){
                setExpirationError({error: false});
            }
            if(key === "cardHolderName" && cardHolderNameError.error){
                setCardHolderNameError({error: false});
            }
            if(key === "CVV" && cvvError.error){
                setCvvError({error: false});
            }
        }

        setPaymentsArray(paymentsArray); // set the state of the paymentsArray
        for(let i = 0; i < paymentsArray.length; i++){
            if(paymentsArray[i].amount !== "" && paymentsArray[i].CVV !== ""
            && paymentsArray[i].cardHolderName !== "" && paymentsArray[i].creditCardNumber !== ""
            && paymentsArray[i].expiration !== "")
            {
            }
            else {
                setIsFormReady(false); 
                return;
            }
        }
        setIsFormReady(true);
    };


    // add another payment object to paymentArray
    const addPayment = (e) => {
        setCounter(counter + 1) ;
        // counter++;

        e.preventDefault();

        // append new payment object to paymentArray
        const newPaymentObject = {...defaultPaymentObject , key: counter + 1}
        // console.log(newPaymentObject)

        setPaymentsArray((oldArray) => [...oldArray, newPaymentObject]);

        setCurrentPayment(numberOfPayments);
        setIsFormReady(false);
        clearErrors();
    };

    // remove payment at index of paymentArray
    const removePayment = (e, index) => {
        // e.preventDefault();

        let filteredArray = paymentsArray.filter(
            (payment) => paymentsArray.indexOf(payment) !== index
        );

        setPaymentsArray(filteredArray);

        // if the first payment is removed
        // go to the payment that was originally next of the first payment
        if (index === 0) {
            setCurrentPayment(0);
        } else {
            // go back to previous payment when 1 is removed
            setCurrentPayment(currentPayment - 1);
        }
        for(let i = 0; i < paymentsArray.length; i++){
            if(paymentsArray[i].amount !== "" && paymentsArray[i].CVV !== ""
            && paymentsArray[i].cardHolderName !== "" && paymentsArray[i].creditCardNumber !== ""
            && paymentsArray[i].expiration !== "")
            {
                setIsFormReady(true);
            }
        }
        clearErrors();
    };

    // handle change amount when a payment is removed
    useEffect(() => {
        handleAmountChange();
        if(props.student.totalDue <= 0){
            // setPaymentConfirmed(true)
        }
    }, [removePayment, props.student.totalDue]);

    const modalSubmitHandler = (ev, modalValue) => {
        // console.log(modalValue);
        setIsOpen(false);
        paymentSubmitHandler(ev);
    }

    const paymentSubmitHandler = (ev) => {
        ev.preventDefault(); //vanilla js that prevents the browser from loading after submit
        let modalIndex;
        if(isFormReady === false) {
            return;
        }
        let payments = [...paymentsArray];
        if(isOpen === false) {
            
            // debugger;
            modalIndex = handleDeposit(payments, Number(props.student.depositDue), Number(props.student.totalDue) - Number(props.student.depositDue))
            if(modalIndex !== -1) {
                setIsOpen(true);
                return;
            }

        }
        setIsFormReady(false);
        setIsOpen(false);


        // debugger;
        if(modalValue === 'Yes') {
            handleSecondCharge(payments, Number(props.student.depositDue), Number(props.student.totalDue - props.student.depositDue));
        }

        handlePaymentTypes(payments, Number(props.student.depositDue) , Number(props.student.totalDue - props.student.depositDue))

        let jsonData = JSON.stringify(payments);
        // console.log('This is what we are sending');
        // console.log(payments);
        // console.log(paymentsArray);
        // console.log(jsonData);
        fetch(urlValidationCC, {
            method: "POST",
            body: jsonData,
            headers: {
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json()) //.json returns a promise
            .then((body) => {

                if (body.success === true) {

                    props.setMessage('Thank you for your payment');
                    props.setShowMessage(true);
                    setPaymentConfirmed(true);

                } else if (body.error === true) {
                    if(body.errorCVV) {
                        setCvvError(body);
                    }
                    else if(body.errorCardHolderName) {
                        setCardHolderNameError(body);
                    }
                    else if(body.errorNumber) {
                        setCardNumberError(body);
                    }
                    else if(body.errorExpiration) {
                        setExpirationError(body);
                    }
                    else if(body.errorAmount) {
                        setAmountError(body)
                    }
                    setIsFormReady(false);
                    setCurrentPayment(body.index);
                }
            });
    };


    return (
        <div className="Payment">
            <div className="paymentContainer">
                <form onSubmit={paymentSubmitHandler}>
                    {/* show amount due */}
                    <div className="formHeader">
                        {/* show amount due normally if it’s a positive number */}
                        {amountDue >= 0 && (
                            <h1>
                                Amount Due:{" "}
                                <CurrencyFormat
                                    value={amountDue}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                    prefix={"$"}
                                />
                            </h1>
                        )}
                        {/* format amount due if it’s a negative number */}
                        {amountDue < 0 && (
                            <h1>
                                Amount Due:{" "}
                                <span style={{ color: "red" }}>
                                    -
                                    <CurrencyFormat
                                        value={Math.abs(amountDue)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                    />
                                </span>
                            </h1>
                        )}
                    </div>

                    {/* This button opens modal */}
                    {/* <button className='modalButton' onClick={() => setIsOpen(true)}>Payment Option</button> */}

                    {/* Modal component */}
                    <PaymentModal 
                        open={isOpen} 
                        onClose={modalSubmitHandler} 
                        modalValueHandler={setModalValue}
                        ccNumbers = {paymentsArray[0].creditCardNumber.substr(paymentsArray[0].creditCardNumber.length - 4)}
                    />
                    <ModalDone
                        open={isOpenModalDone}
                        amountPaid={amountPaid}
                    />

                    <div className="paymentContainer__header">

                        <img className="visa" src={visa} alt="" />
                        <img src={mastercard} alt="" />
                        {/* Section to show amount of money due */}
                        <AmountDue 
                            depositDue={deposit} 
                            totalDue={balance}
                        />
                    </div>

                    {/* Show these fields for credit card */}
                    {paymentsArray.map((payment, index) => {
                        return (
                            <React.Fragment key={payment.key}>
                                {/* only show payment that is selected */}
                                {currentPayment === index && (
                                    <>
                                        <h1>
                                            Payment{" "}
                                            {numberOfPayments > 1
                                                ? `(${
                                                        index + 1
                                                    }/${numberOfPayments})`
                                                : 1}
                                        </h1>
                                        <div className="addAndRemovePaymentButtons">
                                            <button
                                                className="addPayment"
                                                onClick={(e) => {
                                                    addPayment(e, index);
                                                }}
                                            >
                                                Add Optional Additional Payment
                                            </button>

                                            {/* only show these buttons if there is more than 1 payment */}
                                            {numberOfPayments > 1 && (
                                                <>
                                                    <button
                                                        className="removePayment"
                                                        onClick={(e) => {
                                                            removePayment(
                                                                e,
                                                                index
                                                            );
                                                        }}
                                                    >
                                                        Remove Payment
                                                    </button>

                                                    <button
                                                        className={
                                                            firstPayment
                                                                ? "previousPayment"
                                                                : "previousPaymentDisabled"
                                                        }
                                                        onClick={(e) => {
                                                            if (
                                                                firstPayment
                                                            ) {
                                                                setCurrentPayment(
                                                                    currentPayment -
                                                                        1
                                                                );
                                                                clearErrors();
                                                            } else {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    >
                                                        Previous Payment
                                                    </button>

                                                    <button
                                                        className={
                                                            lastPayment
                                                                ? "nextPayment"
                                                                : "nextPaymentDisabled"
                                                        }
                                                        onClick={(e) => {
                                                            if (
                                                                lastPayment
                                                            ) {
                                                                setCurrentPayment(
                                                                    currentPayment +
                                                                        1
                                                                );
                                                                clearErrors();
                                                                
                                                                 // go to next payment
                                                            } else {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    >
                                                        Next Payment
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                        <CurrencyInput
                                            prefix="$"
                                            decimalsLimit={2}
                                            name={`amount${index}`} // names need index number for PHP code to work
                                            placeholder="Amount"
                                            defaultValue={payment.amount.replace(
                                                /[$,]/g,
                                                ""
                                            )} // replace method removes $ and ,
                                            onChange={(e) => {
                                                handleFieldChange(
                                                    e,
                                                    index,
                                                    "amount"
                                                );
                                            }}
                                            className={amountError.error ? "errorBorder" : ""}
                                        />
                                        {amountError.error &&
                                            <span className="errorText">
                                                {amountError.errorDescription}
                                            </span>
                                        }

                                        {/* credit card number field */}
                                        <Cleave
                                            options={{ creditCard: true }}
                                            name={`creditCardNumber${index}`} // names need index number for PHP code to work
                                            placeholder="Credit Card Number"
                                            value={payment.creditCardNumber}
                                            // defaultValue={
                                            //     payment.creditCardNumber
                                            // }
                                            onChange={(e) => {
                                                handleFieldChange(
                                                    e,
                                                    currentPayment,
                                                    "creditCardNumber"
                                                );
                                            }}
                                            className={cardNumberError.error ? "errorBorder" : ""}
                                        />
                                        {/* error text for the card number field */}
                                        {cardNumberError.error &&
                                            <span className="errorText">
                                                {cardNumberError.errorDescription}
                                            </span>
                                        }

                                        {/* credit card expiration field */}
                                        <Cleave
                                            options={{
                                                date: true,
                                                datePattern: ["m", "y"],
                                            }}
                                            name={`expiration${index}`} // names need index number for PHP code to work
                                            placeholder="Credit Card Expiration"
                                            value={
                                                payment.expiration
                                            }
                                            onChange={(e) => {
                                                handleFieldChange(
                                                    e,
                                                    index,
                                                    "expiration"
                                                );
                                            }}
                                            className={expirationError.error === true ? "errorBorder" : ""}
                                        />
                                        {/* error text for the expiration field */}
                                        {expirationError.error &&
                                            <span className="errorText">
                                                {expirationError.errorDescription}
                                            </span>
                                        }

                                        <InputField
                                            defaultValueTest={payment.CVV}
                                            name={`CVV${index}`}
                                            placeholder="CVV"
                                            maxLength={4}
                                            errors={cvvError}
                                            onChangeHook={(e) => {handleFieldChange(e, index, "CVV")}}
                                        />

                                        {/* credit card holder name field */}
                                        <InputField
                                            name={`cardHolderName${index}`} // names need index number for PHP code to work
                                            placeholder="Credit Card Name"
                                            defaultValueTest={
                                                payment.cardHolderName
                                            }
                                            errors={cardHolderNameError}
                                            onChangeHook={(e) => {
                                                handleFieldChange(
                                                    e,
                                                    index,
                                                    "cardHolderName"
                                                );
                                            }}
                                        />
                                    </>
                                )}
                            </React.Fragment>
                        )
                    })
                    }

                    {/* change css class of button depending on amount due */}
                    <button
                        type="submit"
                        className={isFormReady
                                ? "submitButton"
                                : "submitButton submitButtonDisabled"
                        }
                    >
                        Submit
                    </button>
                </form>
                {/* <CheckFooter /> */}
            </div>
            {paymentConfirmed && <Redirect to="/dashboard"></Redirect>}

            <Link to="/dashboard">
                <FontAwesomeIcon icon={faHouse} className="homeButton"/>
            </Link>
        </div>
    );
};

export default Payment;
