import { ImageLineItem } from './../components/ImageLineItem';

import React, { useState, useEffect} from "react";
import Dropzone from "react-dropzone";
import { DragAndDropThumbnail } from './../components/DragAndDropThumbnail';
import "./ImageUpload.scss";

// icons for pdf and heic files
import pdfIcon from "./../images/pdfIcon.png"
import heicIcon from "./../images/heicIcon.png"

import { ToastContainer, toast } from "react-toastify";

export function DragAndDrop({ label, setFiles, files, loading, numberOfImagesAllowed}) {

    // state for background image, background color and text
    const [setImgSrc] = useState()
    const [backgroundColor, setBackgroundColor] = useState('lightgray')
    const [DropzoneAreaText, setDropzoneAreaText] = useState('Only JPEG and PNG images allowed. Please no HEIC, HEIF, PDF, TIFF or other formatted files. No files over 25 MB.')

    // array of json objects containing data for thumbnails and file name for image
    const [imgSrcArray, setImgSrcArray] = useState([{imgSrc: '', fileName: ''}])

    const indexOfLastImage = imgSrcArray.length - 1;

    // state for current thumbnail to display
    const [currentImageIndex, setCurrentImageIndex] = useState(0)

    // this style gets added so background image can change
    const DropzoneAreaBackground = {
        backgroundImage: `url(${imgSrcArray[currentImageIndex].imgSrc})`,
        backgroundColor: backgroundColor
      };

    const plural = numberOfImagesAllowed > 1 ? "s" : ""
    const message = `This section cannot have more than ${numberOfImagesAllowed} image${plural}. Please delete an image before uploading another image.`
    const messageUpload = `This section cannot have more than ${numberOfImagesAllowed} image${plural}.`


    const handleMyResult = (myResult, currentFileName) => {
        /*
            if myResult starts with "data:image/heic;" add a icon
            Or find out why heic files aren't creating thumbnails
            The problem is that the data url tries to download the heic file rather than generate an image
            same goes for "data:application/pdf;"
        */

        const isPDF = myResult.startsWith("data:application/pdf");
        const isHEIC = myResult.startsWith("data:image/heic");

        if (isPDF) {
            // add pdf icon to imgSrcArray
            setImgSrcArray((oldArray) => [...oldArray, {imgSrc: pdfIcon, fileName: currentFileName}])
            console.log('myResult', myResult)
        }

        else if (isHEIC) {
            // add heic icon to imgSrcArray
            setImgSrcArray((oldArray) => [...oldArray, {imgSrc: heicIcon, fileName: currentFileName}])
        }

        else {
            // add thumbnail data to imgSrcArray
            setImgSrcArray((oldArray) => [...oldArray, {imgSrc: myResult, fileName: currentFileName}])
        }

        // remove "Drag and Drop" text
        setDropzoneAreaText('');
    }

    // creates image source for thumbnail
    const createThumbnailImgSrc = (currentFile, currentFileName) => {

        const myFileItemReader = new FileReader();

        // The readAsDataURL method is used to read the contents of the specified Blob or File.
        // myFileItemReader.result will not work unless this is here 
        myFileItemReader.readAsDataURL(currentFile);

        myFileItemReader.addEventListener("load", () => {

            // data url used for background of thumbnail
            const myResult = myFileItemReader.result;

            handleMyResult(myResult, currentFileName);

            }, false);

        // callback from parent component
    }

    const handleOnDrop = (acceptedFiles, rejectedFiles) => {
        console.log('on drop rejected ' + rejectedFiles.length);
        if (loading) {
            return;
        }


        // if there is a rejected file
        if (rejectedFiles && rejectedFiles.length > 0) {
            console.log('in the rejected files if' + rejectedFiles.length);
            console.log(rejectedFiles[0]);

            // error if file is not a valid image file
            const notAnImageFile = rejectedFiles[0].errors[0].code

                console.log(rejectedFiles);
            if(rejectedFiles.length >= 2) {
                console.log('lenght of rejected files' + rejectedFiles.length);
                toast.error(messageUpload, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return;
            }
            if(rejectedFiles[0].file.size >= 25000000) {
                console.log('This File is too big! Files cannot be bigger than 25MB ' + rejectedFiles.length);
                toast.error('This File is too big! Files cannot be bigger than 25MB ', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return;
            }
            if(rejectedFiles[0].errors[0].code == 'file-invalid-type') {
                console.log('This File is not an acceptable file type! ' + rejectedFiles[0].errors[0].message + rejectedFiles.length);
                toast.error('This File is not an acceptable file type! Files must be jpeg, jpg or png.', {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return;
            }
            if (notAnImageFile) {
                setImgSrc('')
                setDropzoneAreaText('Unsupported File Type')
                setBackgroundColor('pink')
            }
        }

        // if there are accepted files
        if (acceptedFiles && acceptedFiles.length > 0) {
            // if user goes past image limit, send toast
            if ((files.length + acceptedFiles.length) > numberOfImagesAllowed) {
                toast.error(message, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return;
            }
            
            setFiles((currFiles) => [...currFiles, ...acceptedFiles]);
            // acceptedFiles are imageBase64Data 
            acceptedFiles.forEach((file) => {
                createThumbnailImgSrc(file, file.name);
            })
        }
    }

    // remove image at index of imgSrcArray
    const removeImage = (index) => {

        if (loading) {
            return;
        }

        let filteredArray = imgSrcArray.filter(
            (payment) => imgSrcArray.indexOf(payment) !== index
        );

        setImgSrcArray(filteredArray);

        let newArray = files.filter((currFile, index, file1) => {
            /* indexToRemove is off by one so need to do indexToRemove - 1 to get the correct index */
                return file1.indexOf(currFile) !== (currentImageIndex - 1)
        });

        setFiles(newArray);

        // if the image is removed
        // go to the image that was originally next of the first image
        if (index === 0) {
            setCurrentImageIndex(0);
        } else {
            // go back to previous image when 1 is removed
            setCurrentImageIndex(currentImageIndex - 1);
        }
    };

    // runs whenever imgSrcArray changes
    useEffect(() => {

        // set thumbnail to be most current image uploaded
        setCurrentImageIndex(indexOfLastImage)

        // check if there's no images
        if (imgSrcArray.length <= 1) {
            // setDropzoneAreaText('Drag and Drop or Click Here')
            setDropzoneAreaText('Only JPEG and PNG images allowed. Please no HEIC, HEIF, PDF, TIFF or other formatted files. No files over 25 MB.')
        }    

    }, [imgSrcArray, setImgSrcArray])
    
    return (
      <div className="DragAndDrop">
        <label className="DragAndDrop__label">{label}</label>
        <div className="DropzoneContainer">
            <Dropzone 
                maxFiles={numberOfImagesAllowed}
                maxSize={25000000}
                accept={'image/jpeg,image/png'}
                onDrop={
                    (acceptedFiles, rejectedFiles) => handleOnDrop(acceptedFiles, rejectedFiles)
                }
            >
                {({ getRootProps, getInputProps }) => (
                    <div className='fileNameContainer'>
                        <DragAndDropThumbnail currentImageIndex={currentImageIndex} imgSrcArray={imgSrcArray} indexOfLastImage={indexOfLastImage} DropzoneAreaBackground={DropzoneAreaBackground} getRootProps={getRootProps} getInputProps={getInputProps} DropzoneAreaText={DropzoneAreaText}  />
                        
                        {/* loop over images and display file names */}
                        <div className="imageLineItems">
                            {imgSrcArray.map((currentImage, index) => {
                                return (
                                    <ImageLineItem key={index} currentImage={currentImage} currentIndex={currentImageIndex} index={index} removeImage={removeImage} setCurrentImageIndex={setCurrentImageIndex} numberOfImages={imgSrcArray.length} />
                                )
                            })}
                        </div>
                    </div>
                )}
            </Dropzone>
        </div>
      </div>
    );
}
