import React, {useEffect, useState} from "react";
import "./Portal.scss";
import ToggleMessage from './ToggleMessage';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMoneyCheckDollar } from "@fortawesome/free-solid-svg-icons";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";

import { Link, Redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";

//LOCAL
import { urlFindSale, urlPref } from "../urls/urls";

const Lookup = ({setMessage, message, setShowMessage, showMessage, setIsLoggedIn, student}) => {

    const [pageIsLoading, setPageIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);

    const [showSales, setShowSales] = useState(false); //displays sale modules button
    const [showTuition, setShowTuition] = useState(false); //displays tuition module button
    const [showImageUpload, setShowImageUpload] = useState(false); //display image upload module
    const [showDormForm, setShowDormForm] = useState(false); //displays dorm form button
    const [showSalesMessage, setShowSalesMessage] = useState(false); //display message if student has already created an invoice
    const [dormFormLink, setDormFormLink]  = useState(""); //emtpy state variable to hold the link for the form form (gotten from database)
    const [noModulesActive, setNoModulesActive] = useState(false);


    const emptySessionObject = sessionStorage.length === 0;
    // console.log(`this is the the sessions object ${sessionStorage}`);
    useEffect(() => {

    // student object is NOT empty and session object is empty
    if (emptySessionObject) {
        // console.log(`error this is the the sessions object ${sessionStorage}`);
        return ( <Redirect to='/' />)

    }
    else {
            setPageIsLoading(true);

            fetch(urlPref , {
                method: "GET",
                headers: {
                    'Content-Type': "application/json"
                }
            })
            .then((response) => response.json())
            .then((body) => {

                let toggleList = body[0].fieldData.WEB_modules_toggle;
                let toggleListBridge = body[0].fieldData.WEB_modules_toggle_Bridge;
                let dormLink = body[0].fieldData.WEB_dorm_form_link;

                if (sessionStorage.isBridge == 'true') {

                    toggleList = toggleListBridge;
                    console.log('Bridge Student')
                }

                console.log(toggleList);

                if(!body.error) {
                    /* set flags and show and display modules */
                    if(toggleList.includes('Tuition')) {
                        setShowTuition(true);

                    }
                    if(toggleList.includes("Image Upload")) {
                        setShowImageUpload(true);

                    }
                    if(toggleList.includes('Dorm Form')) {
                        setShowDormForm(true);
                        setDormFormLink(dormLink)

                    }
                    if(toggleList.includes('Field Trips')) {
                        /* field trips is good to show, but remember a student can only have one invoice 
                         * So query the database again and check if an invoice exits
                         */
                        fetch(urlFindSale + "?id=" + student.idStudent, {
                            method: "GET",
                            headers: {
                                "Content-Type": "application/json",
                            },
                        })
                        .then((response) => response.json()) //.json returns a promise
                        .then((body) => {
                            if (!body.error) {
                                if(body.length < 1) {
                                    //no invoice has been created everything is okay
                                    setShowSales(true);
                                }
                                else {
                                    //an invoice has been created. hide the products button, dont let student buy anything 
                                    setShowSalesMessage(false);
                                    setShowSales(true);
                                }

                            }
                            else if(body.error) {
                                // console.log(`error this is the the sessions object ${sessionStorage}`);
                                if(body['flagNoRecord']) {
                                    setShowSales(true);
                                }
                            }
                            setPageIsLoading(false);
                        })
                        .catch();
                    }
                    else {
                        setPageIsLoading(false);

                    }

                    /* check to see if no modules are active */
                }
            })
            .catch()
            // console.log(`this is the useRef the data is: ${jsonData}`);
            //post request to server . fetch returns a promise
        }


    }, []);


    useEffect(() => {

        if(!showDormForm && !showImageUpload && !showSales && !showTuition) {
            setNoModulesActive(true);
        }
        else {
            setNoModulesActive(false);

        }
    
    }, [showDormForm, showImageUpload, showSales, showTuition])
    

    

    const logOut = () => {

        // clear session storage
        sessionStorage.clear();

        // clear state variables
        setIsLoggedIn(false);
        setMessage('')
        setShowMessage(false)

        // redirect to login page
        return ( <Redirect to='/' /> )
    }

    return (
        <div className="Portal">
            {pageIsLoading && <Backdrop open={pageIsLoading} sx={{color: '#fff', zIndex: 10}}>
                <CircularProgress color="inherit" />
            </Backdrop>}
            
            {/* success message after payment is made */}
            {/* <ToggleMessage 
                message={message}
                setMessage={setMessage}
                setShowMessage={setShowMessage}
                showMessage={showMessage}
            /> */}
            {/* <ToastContainer></ToastContainer> */}

            {/* Logout button */}
            <button className="logoutButton" onClick={logOut} >Logout <FontAwesomeIcon icon={faRightFromBracket} /></button>
            
            {!pageIsLoading && <div className="formContainer" style={
                {
                    justifyContent: "space-around",
                }
            }>
                {showTuition && <Link to="/payments" className="Portal__button payments">
                    <FontAwesomeIcon icon={faMoneyCheckDollar} />
                    <span>Payments</span>
                </Link>}

                {showImageUpload && <Link to="/imageUpload" className="Portal__button imageUpload">
                    <FontAwesomeIcon icon={faCloudArrowUp} />
                    <span>{"Medical Card\n Immunization"}</span>
                    {/* <span className="imageUploadLabel">
                        immunization <br />
                        covid vaccination
                    </span> */}
                </Link>}
                {/* {showSalesMessage && <h2>All additional purchases may be made in person on July 9th</h2>} */}
                {/* {showSalesMessage && <h2>All additional purchases may be made in person on registration day</h2>} */}
                {showSales && <Link to="/products" className={showSalesMessage ? "Portal__button payments Portal__button__disabled" : "Portal__button payments"}>
                    <FontAwesomeIcon icon={faCartShopping} />
                    <span className="imageUploadLabel">{showSalesMessage ? 'All additional purchases may be made in person on registration day' :'Sales'}</span>
                </Link>}
                {showDormForm && <a href={dormFormLink} className="Portal__button home" target="_blank" rel="noreferrer">
                    <FontAwesomeIcon icon={faBuilding} />
                    <span>Dorm Form</span>
                </a>}
                {noModulesActive && 
                    <div className="containerNoModules">
                        <h2>Registration has completed</h2>
                    </div>
                }
            </div>}
        </div>
    );
};

export default Lookup;
