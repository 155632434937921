import React from "react";
export function RadioButton({labelText, value, setValue}) {

    const handleOnClick = (ev) => {
        setValue(ev, value);
    }

    return (
        <div className="RadioButton">
            <input name="paymentOption" type="radio" onClick={handleOnClick} value={value} />
            <label>{labelText}</label>
        </div>
    );
}
